import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Happy Thanksgiving!`}</em></strong>{` `}<strong parentName="p"><em parentName="strong">{`Our Free Thanksgiving Day WOD is Thursday
at 9:00am. If you aren’t a member but would like to attend email:
Daniel\\@crossfittheville.org.`}</em></strong></p>
    <p>{`For time:`}</p>
    <p>{`1500M Row`}</p>
    <p>{`2.0 Mile Assault Bike`}</p>
    <p>{`25-Burpees`}</p>
    <p>{`50-Air Squats (RX+ 45/25`}{`#`}{` Plate)`}</p>
    <p>{`50-Pushups`}</p>
    <p>{`50-Situps (RX+ 25 GHD Situps)`}</p>
    <p>{`20-Pullups (RX+ Bar Muscle Ups)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      